$(function () {
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') }
    });

    $('.dropdown-toggle').dropdown();

    $.extend(true, $.fn.dataTable.defaults, {
        "dom": 'Bfltip',
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        "pageLength": 25,
        "buttons": [
            'copy', 'excel', 'pdf', 'csv'
        ]
    });

    $('#pickadate').datetimepicker({
        format: 'MMMM D, YYYY',
        useCurrent: false,
        maxDate: moment(),
        daysOfWeekDisabled: [
            0, 1, 2, 3, 4, 6
        ]
    });

    $('.report-date').datetimepicker({
        format: 'MMMM D, YYYY',
        maxDate: moment()
    });

    $('#weekly-rankings').datetimepicker({
        format: 'MMMM D, YYYY',
        useCurrent: false,
        maxDate: moment(),
        daysOfWeekDisabled: [
            0, 1, 2, 3, 4, 6
        ]
    });

    $('.start-date').datetimepicker({
        format: 'MMMM D, YYYY',
        useCurrent: false
    });
      $('.end-date').datetimepicker({
        format: 'MMMM D, YYYY',
        maxDate: moment(),
        useCurrent: false
    });
    $(".start-date").on("dp.change", function (e) {
        $('.end-date').data("DateTimePicker").minDate(e.date);
    });
    $(".end-date").on("dp.change", function (e) {
        $('.start-date').data("DateTimePicker").maxDate(e.date);
    });

    $('#category_ids').select2({
        multiple: true,
        placeholder: "Search for Categories",
        minimumInputLength: 2,
        ajax: {
            url: "/api/categories/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.categories};
            }
        },
        initSelection: function(element, callback) {
            var returndata = [];
            $(element.val().split(",")).each(function () {
                $.ajax("/api/categories/one", {
                    data: {
                        category_id: this
                    },
                    dataType: "jsonp"
                }).done(function(data) {
                    returndata.push({id: data.id, name: data.name});
                    callback(returndata);
                });
            });
        },
        formatResult: formatName,
        formatSelection: formatName,
        escapeMarkup: function (m) { return m; }
    });

    $('#product_ids').select2({
        multiple: true,
        placeholder: "Search for Products",
        minimumInputLength: 2,
        ajax: {
            url: "/api/products/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.products};
            }
        },
        initSelection: function(element, callback) {
            var returndata = [];
            $(element.val().split(",")).each(function () {
                $.ajax("/api/products/one", {
                    data: {
                        product_id: this
                    },
                    dataType: "jsonp"
                }).done(function(data) {
                    returndata.push({id: data.id, name: data.name});
                    callback(returndata);
                });
            });
        },
        formatResult: formatName,
        formatSelection: formatName,
        escapeMarkup: function (m) { return m; }
    });

    function formatName(format){
        return format.name;
    }

    function formatTitle(format){
        return format.title;
    }

    $('#spot_id').select2({
        placeholder: "Search for a Spot",
        minimumInputLength: 2,
        ajax: {
            url: "/api/spots/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25,
                    language_id: $('#language_id').val()
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.spots};
            }
        },
        initSelection: function(element, callback) {
            var id=$(element).val();
            if (id!=="") {
                $.ajax("/api/spots/one", {
                    data: {
                        spot_id: id
                    },
                    dataType: "jsonp"
                }).done(function(data) { callback(data); });
            }
        },
        formatResult: formatTitle,
        formatSelection: formatTitle,
        escapeMarkup: function (m) { return m; }
    });

    $('#spot_ids').select2({
        multiple: true,
        placeholder: "Search for Spots",
        minimumInputLength: 2,
        ajax: {
            url: "/api/spots/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25,
                    language_id: $('#language_id').val()
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.spots};
            }
        },
        initSelection: function(element, callback) {
            var returndata = [];
            $(element.val().split(",")).each(function () {
                $.ajax("/api/spots/one", {
                    data: {
                        spot_id: this
                    },
                    dataType: "jsonp"
                }).done(function(data) {
                    returndata.push({id: data.id, title: data.title});
                    callback(returndata);
                });
            });
        },
        formatResult: formatTitle,
        formatSelection: formatTitle,
        escapeMarkup: function (m) { return m; }
    });

    $('#program_id').select2({
        placeholder: "Search for a Program",
        minimumInputLength: 2,
        ajax: {
            url: "/api/programs/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25,
                    language_id: $('#language_id').val()
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.programs};
            }
        },
        initSelection: function(element, callback) {
            var id=$(element).val();
            if (id!=="") {
                $.ajax("/api/programs/one", {
                    data: {
                        program_id: id
                    },
                    dataType: "jsonp"
                }).done(function(data) { callback(data); });
            }
        },
        formatResult: formatTitle,
        formatSelection: formatTitle,
        escapeMarkup: function (m) { return m; }
    });

    $('#program_ids').select2({
        multiple: true,
        placeholder: "Search for Programs",
        minimumInputLength: 2,
        ajax: {
            url: "/api/programs/search",
            dataType: 'jsonp',
            quietMillis: 100,
            data: function (term, page) {
                return {
                    q: term, // search term
                    page_limit: 25,
                    language_id: $('#language_id').val()
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to alter remote JSON data
                return {results: data.programs};
            }
        },
        initSelection: function(element, callback) {
            var returndata = [];
            $(element.val().split(",")).each(function () {
                $.ajax("/api/programs/one", {
                    data: {
                        program_id: this
                    },
                    dataType: "jsonp"
                }).done(function(data) {
                    returndata.push({id: data.id, title: data.title});
                    callback(returndata);
                });
            });
        },
        formatResult: formatTitle,
        formatSelection: formatTitle,
        escapeMarkup: function (m) { return m; }
    });
});

/**
 * Create a confirm modal
 * We want to send an HTTP DELETE request
 *
 * @usage  <a href="posts/2" data-method="delete"
 *          data-modal-text="Are you sure you want to delete"
 *         >
 *
 */
(function() {

  var laravel =
  {
    initialize: function()
    {
        this.methodLinks = $('a[data-method]');
        this.registerEvents();
    },

    registerEvents: function()
    {
        this.methodLinks.on('click', this.handleMethod);
    },

    handleMethod: function(e)
    {
        e.preventDefault();
        var link = $(this);

        var httpMethod = link.data('method').toUpperCase();
        var allowedMethods = ['PUT', 'DELETE'];
        var extraMsg = link.data('modal-text');
        var msg  = '<i class="icon-warning-sign modal-icon"></i>&nbsp;Are you sure you want to&nbsp;' + extraMsg;

        // If the data-method attribute is not PUT or DELETE,
        // then we don't know what to do. Just ignore.
        if ( $.inArray(httpMethod, allowedMethods) === - 1 )
        {
            return;
        }

        bootbox.dialog({
                "message": msg,
                "title": "Please Confirm",
                "buttons": {
                    danger: {
                        label: "Confirm Delete",
                        className: "btn-danger",
                        callback: function() {
                            var form =
                                $('<form>', {
                                    'method': 'POST',
                                    'action': link.attr('href')
                                });
                            var hiddenInput =
                                $('<input>', {
                                    'name': '_method',
                                    'type': 'hidden',
                                    'value': link.data('method')
                                });
                            var csrfToken =
                                $('<input>', {
                                    'name': '_token',
                                    'type': 'hidden',
                                    'value': $('meta[name="csrf-token"]').attr('content')
                                });
                            form.append(hiddenInput).append(csrfToken).appendTo('body').submit();
                        }
                    },
                    close: {
                        label: "Cancel",
                        className: "btn"
                    }
                }
            });
    }
  };

  laravel.initialize();

})();
